import { IDropdownOption } from '@fluentui/react'
import { addMonths, format } from 'date-fns'
import { sumBy } from 'lodash'
import {
  Dictionary,
  IDataFilter,
  NoData
} from '../../../shared/components/DataTableFilters/DataTableFilters'
import {
  IEditOverrideResponse,
  IFeeAssignment,
  IFeeGroupListItem,
  IFeeOverrideListItem,
  IInserUpdateFeeGroupReq,
  IInserUpdateFeeOverrideReq,
  IOverrideDetails,
  ITrustAssignmentListingModel
} from '../api/types/types'

import {
  IClientBilling,
  IExclusion,
  IFeeOverrideForm,
  IFeePlan,
  IOverride,
  ITieredFee
} from './types'

export const getCurrentMonthYearKey = (): string => {
  const today = new Date()
  return format(today, 'MM-yyyy')
}
export const filterFeePlanBySearchtext = (
  items: IFeePlan[],
  searchText: string
) => {
  let filtered: IFeePlan[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.ClientEntityName?.toLowerCase()?.includes(lowerCaseText) ||
        p?.TotalAccrualAmount?.toFixed(2)?.toString()?.startsWith(searchText) ||
        p?.PaymentAccounts?.toLowerCase()?.includes(lowerCaseText) ||
        p?.RepName?.toLowerCase()?.includes(lowerCaseText) ||
        p?.RepId?.toLowerCase()?.includes(lowerCaseText) ||
        p?.MarketValue?.toFixed(2)?.toString()?.startsWith(searchText) ||
        p?.TotalPeriodicFee?.toFixed(2)?.toString()?.startsWith(searchText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const filterFeeBillingBySearchtext = (
  items: IClientBilling[],
  searchText: string
) => {
  let filtered: IClientBilling[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.clientEntityName?.toLowerCase()?.includes(lowerCaseText) ||
        p?.d365ClientId?.toLowerCase()?.includes(lowerCaseText) ||
        p?.household?.toLowerCase()?.includes(lowerCaseText) ||
        p?.feeType?.toLowerCase()?.includes(lowerCaseText) ||
        p?.legalEntity?.toLowerCase()?.includes(lowerCaseText) ||
        p?.trustType?.toLowerCase()?.includes(lowerCaseText) ||
        p?.repName?.toLowerCase()?.includes(lowerCaseText) ||
        p?.marketValue?.toString()?.startsWith(searchText) ||
        p?.paymentAccount?.toLowerCase()?.includes(lowerCaseText) ||
        p?.pmtFrequency?.toLowerCase()?.includes(lowerCaseText) ||
        p?.trustOfficer?.toLowerCase()?.includes(lowerCaseText) ||
        p?.billingStatus?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const getTotalAccruals = (feePlans: IFeePlan[]) => {
  const localFeePlans = feePlans?.map((fee) => ({
    ...fee,
    TotalAccrualAmount: parseFloat(
      sumBy(fee.Accruals, (accrual) => accrual.TotalAccrual || 0).toFixed(2)
    )
  }))
  return localFeePlans
}
export const getMonthYearStringFromSelectedMonth = (
  selectedMonthYear: string
): string => {
  try {
    const splitString = selectedMonthYear?.split('-')
    const currentMonthDate = new Date(
      `${splitString?.[0]}/01/${splitString?.[1]}`
    )
    return format(new Date(currentMonthDate), 'MMM-yyyy')
  } catch {
    return ''
  }
}

export const filterPlansByFilterData = (
  feePlans: IFeePlan[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData = [...feePlans]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IFeePlan] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }

  return filteredData
}

export const filterFeeBillingByFilterData = (
  feeBilling: IClientBilling[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData = [...feeBilling]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IClientBilling] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }

  return filteredData
}

export const getMonthYearListforFeeBilling = (): IDropdownOption[] => {
  const today = new Date()
  const last12Months = []

  for (let i = 0; i < 13; i++) {
    const monthDate = addMonths(today, i * -1)
    last12Months.push({
      key: format(monthDate, 'MM-yyyy'),
      text: format(monthDate, 'LLLL-yyyy')
    })
  }

  return [...last12Months]
}

export const checkIfModified = (feeTiered: ITieredFee[]) => {
  let isModified = false
  if (feeTiered.length > 0) {
    feeTiered.forEach((item) => {
      if (item.marketvalue || item.percentage) {
        isModified = true
      }
    })
  }
  return isModified
}

export const formatInsertUpdateServiceRequest = (
  form: IFeeOverrideForm
): IInserUpdateFeeOverrideReq => {
  return {
    actioncode: 'I',
    overrideSummaryID: form?.overrideSummaryID,
    overrideName: form?.overrideName,
    createdProcess: 'R. UI',
    overrideDetails: [
      ...getOverridesReqForOverrides(form),
      ...getOverridesReqForExclusions(form)
    ]
  }
}

const getOverridesReqForOverrides = (form: IFeeOverrideForm) => {
  return (
    form?.overrides?.map((override) => {
      return {
        overrideSummaryID: form?.overrideSummaryID,
        overrideDetailID: override?.overrideDetailID,
        adminFee: override?.adminFeePct,
        IMFee: override?.imFeePct,
        includeAUM: override?.isIncludedInAum || false,
        overrideType: 'EXDPCT',
        exclusionTypeCode: 'EXBOTH',
        selectedvalue: override?.assetClassKey
          ? `${override?.assetClassKey},${override?.assetClassText}`
          : `${override?.securityKey},${override?.securityName}`,
        isAmount: override?.overrideFeeAmountPercentage === 'A' ? true : false
      } as IOverrideDetails
    }) || []
  )
}

const getOverridesReqForExclusions = (form: IFeeOverrideForm) => {
  return (
    form?.exclusions?.map((exclusion) => {
      return {
        overrideSummaryID: form?.overrideSummaryID,
        overrideDetailID: exclusion?.overrideDetailID,
        includeAUM: exclusion?.isIncludedInAum || false,
        excludeAdminFee: exclusion?.isAdminFeeExcluded || false,
        excludeIMFee: exclusion?.isImFeeExcluded,
        overrideType: 'EXCLSN',
        exclusionTypeCode: 'EXBOTH',
        selectedvalue: exclusion?.assetClassKey
          ? `${exclusion?.assetClassKey},${exclusion?.assetClassText}`
          : `${exclusion?.securityKey},${exclusion?.securityName}`
      } as IOverrideDetails
    }) || []
  )
}

export const buildFormDataFromOverride = (
  override: IEditOverrideResponse
): IFeeOverrideForm => {
  return {
    overrideName: override?.overrideName,
    overrideSummaryID: override?.overrideSummaryID,
    status: override.status,
    exclusions: override?.overrideDetails
      ?.filter((x) => x.overrideType === 'EXCLSN')
      ?.map((y) => {
        return {
          assetClassKey:
            y?.overrideCategory === 'ASTCLS'
              ? y?.selectedvalue?.split(',')?.[0]
              : undefined,
          assetClassText:
            y?.overrideCategory === 'ASTCLS'
              ? y?.selectedvalue?.split(',')?.[1]
              : undefined,
          securityKey:
            y?.overrideCategory === 'SECRTY'
              ? y?.selectedvalue?.split(',')?.[0]
              : undefined,
          securityName:
            y?.overrideCategory === 'SECRTY'
              ? y?.selectedvalue?.split(',')?.[1]
              : undefined,
          isExcluded: undefined,
          isIncludedInAum: y?.includeAUM,
          overrideDetailID: y?.overrideDetailID,
          isAdminFeeExcluded: y?.excludeAdminFee || false,
          isImFeeExcluded: y?.excludeIMFee || false
        } as IExclusion
      }),
    overrides: override?.overrideDetails
      ?.filter((x) => x.overrideType === 'EXDPCT')
      ?.map((y) => {
        return {
          assetClassKey:
            y?.overrideCategory === 'ASTCLS'
              ? y?.selectedvalue?.split(',')?.[0]
              : undefined,
          assetClassText:
            y?.overrideCategory === 'ASTCLS'
              ? y?.selectedvalue?.split(',')?.[1]
              : undefined,
          securityKey:
            y?.overrideCategory === 'SECRTY'
              ? y?.selectedvalue?.split(',')?.[0]
              : undefined,
          securityName:
            y?.overrideCategory === 'SECRTY'
              ? y?.selectedvalue?.split(',')?.[1]
              : undefined,
          isIncludedInAum: y?.includeAUM,
          imFeePct: y?.imFee?.toString(),
          adminFeePct: y?.adminFee?.toString(),
          overrideDetailID: y.overrideDetailID,
          isType: y?.isAmount === true ? 'A' : 'P',
          overrideFeeAmountPercentage: y?.isAmount === true ? 'A' : 'P'
        } as IOverride
      })
  }
}

export const filterFeeOverrideList = (
  list: IFeeOverrideListItem[],
  searchString: string | undefined
) => {
  let filteredUserList = list
  if (searchString) {
    const uppercaseSearchString = searchString?.toUpperCase()
    filteredUserList = list?.filter((x) => {
      if (x?.overrideName?.toUpperCase()?.includes(uppercaseSearchString)) {
        return true
      } else {
        return false
      }
    })
  }

  return filteredUserList
}

export const formatDeleteFeeRequest = (
  item: IFeeOverrideListItem
): IInserUpdateFeeOverrideReq => {
  return {
    actioncode: 'D',
    overrideSummaryID: item?.overrideSummaryID
  }
}

export const formatDeleteFeeGroupRequest = (
  item: IFeeGroupListItem
): IInserUpdateFeeGroupReq => {
  return {
    actioncode: 'D',
    isdraft: item?.isdraft,
    groupSummaryID: item?.groupSummaryID,
    groupSummaryDraftID: item?.groupSummaryDraftID
  }
}
export const filterFeeGroupList = (
  list: IFeeGroupListItem[],
  searchString: string | undefined
) => {
  let filteredUserList = list
  if (searchString) {
    const uppercaseSearchString = searchString?.toUpperCase()
    filteredUserList = list?.filter((x) => {
      if (
        x?.commaseparatedaccnts?.toUpperCase()?.includes(uppercaseSearchString)
      ) {
        return true
      } else if (x?.name?.toUpperCase()?.includes(uppercaseSearchString)) {
        return true
      } else if (
        x?.groupSummaryID?.toString()?.includes(uppercaseSearchString)
      ) {
        return true
      } else {
        return false
      }
    })
  }

  return filteredUserList
}
export const filterFeeAssignment = (
  list: IFeeAssignment[],
  searchString: string | undefined
) => {
  let filteredUserList = list
  if (searchString) {
    const uppercaseSearchString = searchString?.toUpperCase()
    filteredUserList = list?.filter((x) => {
      if (
        x?.clientName?.toUpperCase()?.includes(uppercaseSearchString) ||
        x?.clientID?.toUpperCase()?.includes(uppercaseSearchString) ||
        x?.accountNumber?.toUpperCase()?.includes(uppercaseSearchString) ||
        x?.overrideName?.toUpperCase()?.includes(uppercaseSearchString) ||
        x?.feeStructureName?.toUpperCase()?.includes(uppercaseSearchString) ||
        x?.status?.toUpperCase()?.includes(uppercaseSearchString)
      ) {
        return true
      } else {
        return false
      }
    })
  }

  return filteredUserList
}
export const filterTrustCategoryAssignment = (
  list: ITrustAssignmentListingModel[],
  searchString: string | undefined
) => {
  let filteredUserList = list
  if (searchString) {
    const uppercaseSearchString = searchString?.toUpperCase()
    filteredUserList = list?.filter((x) => {
      if (
        x?.clientEntityId?.toUpperCase()?.includes(uppercaseSearchString) ||
        x?.clientEntityName?.toUpperCase()?.includes(uppercaseSearchString) ||
        x?.partyUniqueId?.toUpperCase()?.includes(uppercaseSearchString) ||
        x?.trustType?.toUpperCase()?.includes(uppercaseSearchString)
      ) {
        return true
      } else {
        return false
      }
    })
  }

  return filteredUserList
}

export const getFeeCategoryDisplayValue = (feeCategory: string) => {
  if (feeCategory.toLowerCase() === 'flat_fee') {
    return 'Flat Fee'
  }
  return feeCategory
}
